import _ from 'lodash'
export class LocalExecucaoModel {
  constructor({ 
    id,
    nome,
    instalacaoCliente = false,
    descricaoCertificado,
    flagAgendamento = false,
    flagEnvioMaterial = false}) {
    this.id = id;
    this.nome = nome;
    this.instalacaoCliente = instalacaoCliente;
    this.descricaoCertificado = descricaoCertificado;
    this.flagAgendamento = flagAgendamento;
    this.flagEnvioMaterial = flagEnvioMaterial;
  }

  get request(){
    const retorno = _.cloneDeep(this);

    if(retorno.instalacaoCliente) retorno.flagEnvioMaterial = false;
    else retorno.flagAgendamento = false;

    return retorno
  }
}
