<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-6"
        :label="$t('modulos.local_execucao.formulario.nome')"
        :max="50"
        obrigatorio
      />
      <input-radio
        v-model="form.instalacaoCliente"
        :divider="false"
        class="col-12 col-md-2"
        :options="opcoes.booleano"
        :label="$t('modulos.local_execucao.formulario.instalacaoCliente')"
      />
      <input-radio
        v-model="form.flagEnvioMaterial"
        :disabled="form.instalacaoCliente"
        :divider="false"
        :tooltip-help="
          $t('modulos.local_execucao.tooltip.os_a_partir_de_envio_de_material')
        "
        class="col-12 col-md-2"
        :options="opcoes.booleano"
        :label="
          $t(
            'modulos.local_execucao.formulario.os_a_partir_de_envio_de_material'
          )
        "
      />
      <input-radio
        v-model="form.flagAgendamento"
        :disabled="!form.instalacaoCliente"
        :divider="false"
        class="col-12 col-md-2"
        :tooltip-help="
          $t('modulos.local_execucao.tooltip.os_a_partir_de_agendamento')
        "
        :options="opcoes.booleano"
        :label="
          $t('modulos.local_execucao.formulario.os_a_partir_de_agendamento')
        "
      />
      <input-textarea
        v-model="form.descricaoCertificado"
        obrigatorio
        :max="500"
        class="col-12"
        :label="$t('modulos.local_execucao.formulario.descricao_certificado')"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import LocalExecucaoService from '@common/services/cadastros/LocalExecucaoService.js';
import { LocalExecucaoModel } from '@common/models/cadastros/LocalExecucaoModel.js';

export default {
  props: ['id'],
  data() {
    return {
      form: new LocalExecucaoModel({}),
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.local_execucao.titulos.editar');
      return this.$t('modulos.local_execucao.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'LocalExecucao', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'LocalExecucao', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalExecucaoService.buscar(this.id)
        .then((res) => {
          this.form = new LocalExecucaoModel(res.data);
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.local_execucao.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.$refs.form.validate()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalExecucaoService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.local_execucao.cadastro_sucesso`));
          this.$router.push({ name: 'local-execucao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'local-execucao' });
      });
    },
  },
};
</script>
